
import { Component, Vue } from 'vue-property-decorator';
import { RoundUpService, MemberService } from '@/services';

// Config Variables
import { ActiveTenant } from '../../../active-tenant';
import { tenants } from '../../../tenant-config';

// Components
import { RoundUpAbout, RoundUpToolTip, RoundUpMinMaxAmountTooltip } from '@/components/round-up';
import { SuccessPage } from './components';
import { FeatureModule, RoundUpModule } from '@/store/modules';

// Types
import { IRoundUpRuleResponse } from '@/types/round-up.types';
import { CardDetail } from '@/types/user.types';
import { IBasePaged } from '@/types/base.types';

@Component({
  components: { RoundUpAbout, RoundUpToolTip, RoundUpMinMaxAmountTooltip, SuccessPage }
})
export default class AddRoundUpProgram extends Vue {
  currency: any;

  // Tenant
  currentTenant = tenants[ActiveTenant];
  blueTextColor = this.currentTenant.blueTextColor;

  enrollRoundUpTitle = this.currentTenant.roundUps.enrollRoundUpTitle;
  enrollRoundUpTitleColor = this.currentTenant.roundUps.enrollRoundUpTitleColor;
  enrollRoundUpDescriptionText = this.currentTenant.roundUps.enrollRoundUpDescriptionText;
  enrollRoundUpDescriptionTextColor = this.currentTenant.roundUps.enrollRoundUpDescriptionTextColor;
  enrollRoundUpPlatformFeeText = this.currentTenant.roundUps.enrollRoundUpPlatformFeeText;
  ecosystemFee = RoundUpModule.getEcosystemFee ?? 0;
  isEcosystemFeeEnabled = FeatureModule.getFeatureStatuses.isEcosystemFeeEnabled;
  privacyLink = this.currentTenant.links.privacyPolicy;
  showSuccess = false;
  successMessage = false;
  termsLink = this.currentTenant.links.termsConditions;

  // Round up
  ecosystemMinimum = RoundUpModule.getEcosystemMinimum ?? 0;
  isFeeEnabledRoundUp = false;
  maximumRoundUp: number | null = null;
  minimumRoundUp: number | null = RoundUpModule.getEcosystemMinimum;

  cards: IBasePaged<CardDetail> = {
    totalNumberOfPages: 0,
    totalNumberOfRecords: 0,
    items: []
  };

  created() {
    this.getLinkedCards();
    if (!this.ecosystemMinimum) this.getRoundUpGlobalSettings();
  }

  async getRoundUpGlobalSettings(): Promise<void> {
    try {
      await RoundUpService.getRoundUpGlobalSettings();
      if (!this.minimumRoundUp) this.minimumRoundUp = RoundUpModule.getEcosystemMinimum;
    } catch (e: any) {
      this.$notifyError('Something went wrong');
    }
  }

  getDefaultLast4Digits(cardsPaginated: IBasePaged<CardDetail>): string | undefined {
    if (!cardsPaginated || !Array.isArray(cardsPaginated.items)) {
      return undefined;
    }

    const defaultCard = cardsPaginated.items.find((item: CardDetail) => item.isDefault);
    return defaultCard?.last4Digits;
  }

  async getLinkedCards(): Promise<void> {
    this.cards = await MemberService.getCards();
  }

  async createRoundUpRule(
    minimumAmount: number | null,
    maximumAmount: number | null
  ): Promise<IRoundUpRuleResponse> {
    const roundUp = await RoundUpService.createRoundUpRule({
      minimumRoundUps:
        minimumAmount && minimumAmount !== this.ecosystemMinimum ? Number(minimumAmount) : undefined,
      maximumRoundUps: maximumAmount ? Number(maximumAmount) : undefined,
      isFeeEnabled: this.isFeeEnabledRoundUp
    });
    this.successMessage = true;

    setTimeout(() => {
      this.showSuccess = true;
    }, 1000);

    return roundUp;
  }
}
